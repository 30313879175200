import H1 from "./H1";
import H2 from "./H2";
import React from "react";
import H3 from "./H3";

/** @type {import('mdx/types.js').MDXComponents} */
export const components = {
  em(props: object) {
    return <i {...props} />;
  },
  h1: H1,
  h2: H2,
  h3: H3,
};

export const blog_page_styles = {
  color: "#232129",
  paddingTop: 16,
  paddingBottom: 96,
  paddingRight: "20rem",
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  fontSize: 24,
};
